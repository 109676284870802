/*
Color scheme?

#5C346F
#9C0F9C
#7C5B8B
#0F9C9C #4FD4CF
#C19531

#EBE1CE (<- background option)

*/
.App {
    text-align: center;
    /* background: #000; */
    background-color: #282c34;
    min-height: 100vh;
}

.App-header {
    color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
}

.fade-in {
    /* transition: opacity 1s ease-in-out; */
    opacity: 1;
    animation: fade 0.75s linear;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.button-style-font-rip {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}
